import React, { Fragment } from "react";
import Blaupause from "./Blaupause";

import Header from "./../Header";
import SecHeadline from "./../SecHeadline";
import GridContainer from "./../GridContainer";
import LocElement from "./../LocElement";

import Hint from "./modules/Hint";

class SingleChoice extends Blaupause {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      disabled: false,
      modus: "intro",
      screenCounter: 0
    };
  }

  userMayProceed() {
    let response = "question-unfinished";
    let control = this.itemsToShow.filter(item => this.state.selected.hasOwnProperty(item._id));
    if (control.length === this.itemsToShow.length) {
      response = "multipart-change";
      if (this.state.screenCounter + 1 === this.screensLength) {
        response = "question-finished";
      }
    }
    return response;
  }

  setAnswer(iID, key) {
    let selected = this.state.selected;
    selected[iID] = key;
    this.setState({ selected: selected });
    if (this.userMayProceed() !== "question-unfinished") {
      this.props.sendToMCP("timer advanced");
    }
  }

  render() {
    this.itemsToShow = this.props.data.items ? this.props.data.items.slice(this.state.screenCounter * this.props.data.config.maxItemsPerScreen, (this.state.screenCounter + 1) * this.props.data.config.maxItemsPerScreen) : [];

    let contentClasses = ["content"];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);
    if (this.props.data.config.maxItemsPerScreen > 1) {
      contentClasses.push("rows");
      if (this.props.data.config.hasSeperators) {
        contentClasses.push("lined");
      }
    }
    let headerClasses = ["test", this.state.modus];

    return (
      <Fragment>
        <Header mode={headerClasses.join(" ")} config={this.props.data} />
        <div id="main" className="template single-choice">
          {
            // Check if SecHeadline is needed
            ((this.state.modus === "intro" && this.props.data.example) || this.state.modus === "question") && <SecHeadline example={this.props.data.example && this.state.modus === "intro" ? this.props.data.example : null} text={this.props.data.secondaryHeadline} />
          }
          <div className={contentClasses.join(" ")}>
            {this.state.modus === "intro" && (
              <Fragment>
                {this.props.data.example &&
                  this.props.data.example.map((ex, idx) => {
                    let txt = ex.text ? (
                      <div className="question">
                        <LocElement {...ex.text} />
                      </div>
                    ) : null;

                    let answers = Object.keys(ex.answers).map((key, index) => {
                      let btnClasses = ["answer", "xmpl"];
                      if (ex.answers[key].german.textType && ex.answers[key].german.textType === "svg") btnClasses.push("isImage");
                      if (ex.validation[key] === 1) btnClasses.push("selected");
                      return (
                        <div className="col" key={`expl-answer${key}_${index}`}>
                          <button className={btnClasses.join(" ")} onClick={null} type="button" disabled={this.state.disabled}>
                            <LocElement {...ex.answers[key]} />
                          </button>
                        </div>
                      );
                    });

                    let exampleDescription = this.props.data.exampleDescription ? (
                      <div className="xmplDescription">
                        <div className="container">
                          <LocElement {...this.props.data.exampleDescription} />
                        </div>
                      </div>
                    ) : null;

                    return (
                      <Fragment key={`expl-task${idx}`}>
                        <div className="task">
                          {txt}
                          <GridContainer className="answers" {...ex.config}>
                            {answers}
                          </GridContainer>
                        </div>
                        {exampleDescription}
                      </Fragment>
                    );
                  })}
                {this.props.data.hint && <Hint text={this.props.data.hint} />}
              </Fragment>
            )}
            {this.state.modus === "question" &&
              this.itemsToShow.map((item, index) => {
                let item_id = item._id;
                let questClasses = ["question"];
                if (item.config && item.config.breakAfterQuestion) questClasses.push("fullwidth");
                let txt = item.text ? (
                  <div className={questClasses.join(" ")}>
                    {this.props.data.config.hasNumberedItems ? <div className="item-num">{this.state.screenCounter * this.props.data.config.maxItemsPerScreen + index + 1}.</div> : null}
                    <LocElement {...item.text} id={`text${index}`} />
                  </div>
                ) : this.props.data.config.hasNumberedItems ? (
                  <div className="question num-only">
                    <div className="item-num">{this.state.screenCounter * this.props.data.config.maxItemsPerScreen + index + 1}.</div>
                  </div>
                ) : null;
                let answers = Object.keys(item.answers).map((key, idx) => {
                  let btnClasses = ["answer"];
                  if (this.state.selected[item_id] && this.state.selected[item_id] === key) btnClasses.push("selected");
                  if (item.answers[key].german.textType && item.answers[key].german.textType === "svg") btnClasses.push("isImage");
                  // console.log(`answer${index}_${idx}_${this.state.screenCounter}`);
                  return (
                    <div className="col" key={`answer${index}_${idx}_${this.state.screenCounter}`}>
                      <button
                        className={btnClasses.join(" ")}
                        onClick={e => {
                          this.setAnswer(item_id, key);
                        }}
                        type="button"
                        disabled={this.state.disabled}>
                        <LocElement {...item.answers[key]} />
                      </button>
                    </div>
                  );
                });
                return (
                  <div key={`task-${item_id}-${index}`} className="task">
                    {txt}
                    <GridContainer className="answers" {...item.config}>
                      {answers}
                    </GridContainer>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SingleChoice;
