import React from 'react';
import renderMarkup from './../utils/RenderMarkup';
import SVG from 'react-inlinesvg';
import User from './../User';
import Loader from 'react-loaders';

const _workReplacements = (text, replacements) => {
  let renderableText = text;
  renderableText = renderableText.split('_#LINEBREAK#_').join('\\n');
  Object.keys(replacements).forEach(k => renderableText.split(`#${k.toUpperCase()}#`).join(replacements[k]));
  return renderableText;
};

export default props => {
  let txt = props[User.lang];
  let replacements = props._replacements || {};
  if (!txt) return null;
  if(typeof txt === "string") return (<span>{renderMarkup(_workReplacements(txt, replacements))}</span>);
  if(!txt.text) return null;
  switch (txt.textType) {
    case "text":
      return (<span>{renderMarkup(_workReplacements(txt.text, replacements))}</span>);
    case "image":
      return (<img src={txt.text} alt="" />);
    case "mathml":
      return (renderMarkup(txt.text));
    case "inline-svg":
      return (<span className="ziggy">{renderMarkup(txt.text)}</span>);
    case "svg":
      let style = txt.style ? txt.style : null;
      return (<div className="media" style={style}>
        <SVG
          cacheRequests={true}
          loader={<Loader type="line-scale-pulse-out-rapid" active />}
          src={txt.text}
        />
      </div>);
    case "html":
      return (renderMarkup(txt.text));
    default:
      return (renderMarkup(txt.text));
  }
}
