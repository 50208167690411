class DataPersister
{
  constructor() {
    this._quiet = true;
    this._log(`DataPersister online`);
  }

  _log(msg){
    !this._quiet && console.log(msg);
  }

  getItem(key, defaultValue) {
    let val = localStorage.getItem(key)
    val = val === "undefined" ? undefined : JSON.parse(val);
    return val || defaultValue;
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  setItem(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  clearEverything() {
    localStorage.clear();
  }
}

export default new DataPersister();
