import React, {Component} from 'react';
import Logo from './../components/Logo.js';
import Loadbar from './../components/Loadbar.js';
import DataFetcher from './../utils/DataFetcher';
import DataPersister from './../utils/DataPersister';
import Localizator from './../utils/Localizator';
import User from './../User';
import imgsToPreload from "./SplashScreenImgsToLoad";
import BodySpy from './../BodySpy';

const __LOCALIZATION_FETCHING_THRESHOLD = 1000*60*60*12; //12h
const __CLIENT_FETCHING_THRESHOLD = 1000*60*60*24; //24h

function SplashScreen(WrappedComponent)
{
  return class extends Component {

    state = {
      loading: true,
      currentStep: -1,
      loadbarCurrent: 0
    };

    steps = (() => {
      let s = [/* TODO: add user aktualisieren falls einer vorhanden im localstorage ;) */];

      s = s.concat([
        { text: "Initialisiere Benutzer", fn: this._loadingStepUser.bind(this) },
        { text: "Lade Inhalte", fn: this._loadingStepLocalization.bind(this) },
        { text: "Lade Flow", fn: this._loadingStepFlow.bind(this) },
        { text: "Lade Klient", fn: this._loadingStepClient.bind(this) }
      ]);

      imgsToPreload.map((file, i) => {
        return s.push({ text: `Lade SVG (${i+1}/${imgsToPreload.length}) - ${file}`, fn: () => { this._loadSingleImage(file) } })
      });
      return s;
    })();

    _loadingStepUser() {
      DataPersister.removeItem("initialUserData");
      const queryParams = new URLSearchParams(window.location.search);
      let initByToken = queryParams.get('initByToken');
      if(!initByToken){
        this.nextLoadingStep();
        return;
      }

      DataFetcher.fetch('decodeInitialUserToken', {initToken: initByToken})
        .then(res => {
          if(res.data.responseType === "newUser"){
            DataPersister.removeItem("user");
            DataPersister.setItem("initialUserData", res.data.data);
          }
          if(res.data.responseType === "oldUser"){
            delete res.data.data.user._id;
            delete res.data.data.user.updated_at;
            delete res.data.data.user.__v;
            DataPersister.setItem("flow", res.data.data.flow);
            let u = res.data.data.user;
            u.isRegistered = true;
            DataPersister.setItem("user", u);
          }
          User.initDataByPersister();

          this.nextLoadingStep();
        });
    }

    _loadingStepClient() {
      /* NOTE @mflindt dieser teil wird für dip/staging/production/dev einkommentiert wenn "subdomain-tests" durch sind */
      let subdomain = window.location.hostname.split('.')[0];
      let client = DataPersister.getItem('client', null);
      if(client && client._timestamp && Date.now() - client._timestamp < __CLIENT_FETCHING_THRESHOLD && client.subdomain === subdomain){
        this.nextLoadingStep();
        return;
      }

      if(process && process.env && Boolean(["development", "staging", "dip"].includes(process.env.REACT_APP_DEPLOYMENT_TYPE))) {
        this.nextLoadingStep();
        return;
      }
      /* bis hier */

      /* NOTE @mflindt dieser teil hier wird für "subdomain"-tests einkommentiert" *
      let subdomain = "anton-autolizensierender-mandant";
      let client = null;
      /* bis hier */

      DataFetcher.fetch('client', {subdomain})
        .then(res => {
          if(!res.success){
            alert(res.msg);
            return window.location.href = "https://kompetenzcheck21.de";
            //return this.nextLoadingStep();
          }
          DataPersister.setItem("client", res.client);
          this.nextLoadingStep();
        });
    }

    _loadingStepLocalization() {
      let localization = DataPersister.getItem('localization', null);
      if(localization && localization._timestamp && Date.now() - localization._timestamp < __LOCALIZATION_FETCHING_THRESHOLD){
        Localizator.data = localization;
        this.nextLoadingStep();
        return;
      }

      DataFetcher.fetch("content/localization", {})
        .then(response => {
          let c = response.content;
          Localizator.data = c;
          DataPersister.setItem("localization", c);
          this.nextLoadingStep();
        });
    }

    _loadingStepFlow() {
      let flow = DataPersister.getItem('flow', null);
      let f = User.isRegistered ? User.getDataEntry("flow") : null;
      if(flow && (!f || flow.pool._id === f.pool._id)){
        User.setNewFlow(flow)
        this.nextLoadingStep();
        return;
      }

      let fO = f ? {pool: f.pool._id} : {};
      DataFetcher.fetch("content/flow", fO)
        .then(response => {
          let c = response.content;
          DataPersister.setItem("flow", c);
          User.setNewFlow(c);
          this.nextLoadingStep();
        });
    }

    _loadSingleImage(file) {
      fetch(file).then(() => this.nextLoadingStep()).catch(() => this.nextLoadingStep());
    }

    nextLoadingStep() {
      this.setState({loadbarCurrent: this.state.loadbarCurrent + 1});
      if(!this.state.loading) return;
      let curStep = this.state.currentStep + 1;
      if(this.steps[curStep]){
        return this.setState({currentStep: curStep}, () => this.steps[curStep].fn());
      }
      this.setState({loading: false}, () => {BodySpy.onResize()});
    }

    componentDidMount() {
      setTimeout(this.nextLoadingStep.bind(this), 500);
    }

    render() {
      // while checking user session, show "loading" message
      if(this.state.loading) return (
        <div className="SplashScreen">
          <div className="SplashScreen-header">
            <Logo mode="wide" />
            <Loadbar current={this.state.loadbarCurrent} total={this.steps.length + 1} />
          </div>
        </div>
      );

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default SplashScreen;
