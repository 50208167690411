class DataFetcher {
  constructor() {
    this.bridgeUrl = `${process.env.REACT_APP_BRIDGE_URL}/pwa/`;
    this._quiet = true;
    this._log(`online -> ${this.bridgeUrl}`);
  }

  _log(msg) {
    !this._quiet && console.log("DataFetcher", msg, Date.now());
  }

  fetch(endpoint, body) {
    this._log(`fetch -> ${endpoint} with ${JSON.stringify(body)}`);
    return this._fetch(endpoint, body);
  }

  abortableFetch(endpoint, body){
    this._log(`abortableFetch -> ${endpoint} with ${JSON.stringify(body)}`);

    const controller = new AbortController();
    const { signal } = controller;
    return {
      abort: () => controller.abort(),
      ready: this._fetch(endpoint, body, signal)
    }
  }

  _fetch(endpoint, body, signal = null){
    let opts = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      }
    };
    if(signal) opts.signal = signal;

    return fetch(`${this.bridgeUrl}${endpoint}`, opts)
      .then((r) => r.json())
      .catch((e) => console.error(e));
  }
}

export default new DataFetcher();
