import React, { Component, Fragment } from 'react';
import Blaupause from './Blaupause';

import Header from './../Header';
import SecHeadline from './../SecHeadline';
import GridContainer from './../GridContainer';
import LocElement from './../LocElement';

import {default as Timer, Messages as TimerMessages} from './../../Timer';

class Preview extends Component
{
  constructor(props) {
    super(props);
    this.state = { counter: 0 }
    this._timerId = null;
    this.max = props.data.length;
  }

  startTimer(value) {
    this.props.onPreviewTime(value * 1000);
    if(this._timerId) Timer.deregister(this._timerId);
    this._timerId = Timer.register({
      maxValue: value * 1000,
      cb: (o) => {
        this._timerId = null;
        this.props.onPreviewTime(0);
        if(o.msg === TimerMessages.KILLED) return;
        if(o.msg === TimerMessages.FINISHED) {
          if (this.state.counter + 1 < this.max) {
            this.setState({counter: this.state.counter + 1}, () => {
              return this.startTimer(value);
            });
          } else {
            return this.props.onPreviewFinish();
          }
        }
      },
      step: (o) => {
        return this.props.onPreviewTime(value * 1000 - o.msTimePassed);
      }
    })
  }

  componentDidMount() { this.startTimer(this.props.time); }
  componentWillUnmount() {
    //console.log('Preview sagt Ciao');
    if(this._timerId) Timer.deregister(this._timerId);
    this._timerId = null;
  }

  render() {

    // let slides = this.props.data.map((slide, index) => {
    //   return (
    //     <div className="slide" key={`slide-${index}`}>
    //       <LocElement {...slide} />
    //     </div>
    //   );
    // });

    return (
      <div className="preview-stage">
        <div className="slides">
          {/* {slides} */}
          <div className="slide">
            <LocElement {...this.props.data[this.state.counter]} />
          </div>
        </div>
      </div>
    );
  }
}

class PreviewSingleChoice extends Blaupause
{
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      disabled: false,
      modus: 'intro',
      screenCounter: 0
    }
  }

  onMCPClick() {
    switch (this.state.modus) {
      case "intro":
        this.setState({ modus: 'preview' });
        return { status: "enter-preview" };
      case "preview":
        // this.setState({ modus: 'question' });
        return { status: "previewing" };
      default:
        switch (this.userMayProceed()) {
          case "question-finished":
            return { status: "question-finished", answers: this.collectData() }
          case "multipart-change":
            this.props.disableMCP(true);
            this.setState({ screenCounter: this.state.screenCounter + 1 }, () => {
              this.props.disableMCP(false);
            });
            return { status: "multipart-change", timer: this.time };
          default:
            return { status: this.userMayProceed() };
        }
    }
  }





  onPreviewFinish() {
    this.setState({ modus: 'question' });
    this.props.startQuestion({ timer: this.time })
  }

  userMayProceed() {
    let response = "question-unfinished";
    let control = this.itemsToShow.filter(item => this.state.selected.hasOwnProperty(item._id));
    if (control.length === this.itemsToShow.length) {
      response = "multipart-change";
      if (this.state.screenCounter + 1 === this.screensLength) {
        response = "question-finished";
      }
    }
    return response;
  }

  setAnswer(iID, key) {
    let selected = this.state.selected;
    selected[iID] = key;
    this.setState({ selected: selected });
    if (this.userMayProceed() !== 'question-unfinished') {
      this.props.sendToMCP('timer advanced');
    }
  }

  render() {

    let headerConfig = {
      _id: this.props.data._id,
      headline: this.props.data.headline,
      description: (this.state.modus === 'question' ? this.props.data.descriptionOnPlay : this.props.data.description)
    }

    let secHeading = {
      text: (this.state.modus === 'preview' ? this.props.data.secondaryHeadline : this.props.data.secondaryHeadlineOnPlay),
      example: (this.props.data.example && this.state.modus === 'intro' ? this.props.data.example : null)
    }

    this.itemsToShow = this.props.data.items ? this.props.data.items.slice(this.state.screenCounter*this.props.data.config.maxItemsPerScreen, (this.state.screenCounter+1) * this.props.data.config.maxItemsPerScreen) : [];

    let contentClasses = ['content'];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);
    if (this.props.data.config.maxItemsPerScreen > 1) contentClasses.push('rows');

    let headerClasses = ['test', this.state.modus];

    let previewTime = this.props.data.previewCountdown / this.props.data.previews.length;

    return (
      <Fragment>
        <Header mode={headerClasses.join(' ')} config={headerConfig} />
        <div id="main" className="template preview-single-choice">
          {
            // Check if SecHeadline is needed
            ((this.state.modus === "intro" && this.props.data.example) || this.state.modus === "preview" || this.state.modus === "question") &&
            <SecHeadline {...secHeading} />
          }
          <div className={contentClasses.join(' ')}>
            {
              this.state.modus === "intro" && (
                null
              )
            }
            {
              this.state.modus === "preview" && (
                <Preview
                  data={this.props.data.previews}
                  time={previewTime}
                  onPreviewTime={this.props.showTime.bind(this)}
                  onPreviewFinish={this.onPreviewFinish.bind(this)}
                />
              )
            }
            {
              this.state.modus === "question" && (
               this.itemsToShow.map((item, index) => {
                 let item_id = item._id;
                 let txt = item.text ? (
                   <div className="question">
                     {
                       this.props.data.config.hasNumberedItems ? (
                         <div className="item-num">{this.state.screenCounter * this.props.data.config.maxItemsPerScreen + index + 1}.</div>
                       ) : null
                     }
                     <LocElement {...item.text} id={`text${index}`} />
                   </div>
                 ) : (
                   this.props.data.config.hasNumberedItems ? (
                     <div className="question num-only">
                       <div className="item-num">{this.state.screenCounter*this.props.data.config.maxItemsPerScreen+index+1}.</div>
                     </div>
                   ) : null
                 );
                 let answers = Object.keys(item.answers).map((key, idx) => {
                   let btnClasses = ['answer'];
                   if (this.state.selected[item_id] && this.state.selected[item_id] === key) btnClasses.push('selected');
                   if (item.answers[key].german.textType && item.answers[key].german.textType === 'svg') btnClasses.push('isImage')
                   return (
                     <div className="col" key={`answer${index}_${idx}_${this.state.screenCounter}`}>
                       <button
                         className={btnClasses.join(' ')}
                         onClick={(e) => {this.setAnswer(item_id, key)}}
                         type="button"
                         disabled={this.state.disabled}>
                         <LocElement {...item.answers[key]} />
                     </button>
                     </div>
                   )
                 });
                 return (
                   <div key={`task-${item_id}-${index}`} className="task">
                     {txt}
                     <GridContainer className='answers' {...item.config}>
                       {answers}
                     </GridContainer>
                   </div>
                 );
               })
             )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PreviewSingleChoice
