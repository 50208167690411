import React, { Component } from "react";

class Blaupause extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.screensLength = this.props.data.items.length / this.props.data.config.maxItemsPerScreen;
    this.time = this.props.data.countdown ? this.props.data.countdown / (this.props.data.items.length / this.props.data.config.maxItemsPerScreen) : undefined;
    // this.time = 30;
  }

  collectData() {
    return this.state.selected;
  }

  getOrCreateRef(refs, id) {
    if (!refs.hasOwnProperty(id)) {
      refs[id] = React.createRef();
    }
    return refs[id];
  }

  userMayProceed() {}

  timesUp() {
    this.disabled(true);
    if (this.state.screenCounter + 1 >= this.screensLength || this.userMayProceed() === "question-finished") {
      this.props.timesUp({ status: "finish", answers: this.collectData() });
    } else {
      if (this.state.screenCounter + 1 < this.screensLength) {
        this.props.disableMCP(true);
        this.setState({ screenCounter: this.state.screenCounter + 1 }, () => {
          this.props.disableMCP(false);
        });
        this.props.timesUp({ status: "change", time: this.time });
      }
    }
  }

  onMCPClick() {
    switch (this.state.modus) {
      case "intro":
        this.setState({ modus: "question" });
        return { status: "start-question", timer: this.time };
      default:
        switch (this.userMayProceed()) {
          case "question-finished":
            return { status: "question-finished", answers: this.collectData() };
          case "multipart-change":
            this.props.disableMCP(true);
            this.setState({ screenCounter: this.state.screenCounter + 1 }, () => {
              this.props.disableMCP(false);
            });
            // return { status: this.userMayProceed(), timer: this.time };
            return { status: "multipart-change", timer: this.time };
          default:
            return { status: this.userMayProceed() };
        }
    }
  }

  disabled(bool) {
    this.setState({ disabled: bool });
  }
}

export default Blaupause;
