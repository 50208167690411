import React, { Component, Fragment } from "react";
import Header from "./../components/Header";
import LocElement from "./../components/LocElement";
// import Icon from "./../components/Icon";
import MCP from "./../components/MCP";
import User from "./../User";
import { Redirect } from "react-router-dom";

import DataFetcher from "./../utils/DataFetcher";

const MESSAGES = {
  pwdIsEmpty: "Passwort darf nicht leer sein.",
  pwdTooShort: "Passwort muss mind. 8 Zeichen lang sein.",
  pwdMismatch: "Passwörter müssen identisch sein.",
  finished: {
    heading: "Dein Passwort wurde zurückgesetzt.",
    paragraphs: ["Klicke unten um zum Login zu gelangen."]
  }
};

class ResetPwd extends Component {
  constructor(props) {
    super(props);
    this.userID = this.props.match.params.id;
    this.state = {
      mcpMode: "register",
      isSending: false,
      isFinished: false,
      gotoLogin: false,
      focus1: false,
      focus2: false,
      field1Hidden: true,
      field2Hidden: true,
      pwd1: "",
      pwd2: "",
      fail: false,
      intro: {
        german: "Bitte vergieb ein neues Passwort und bestätige es.<br>Das Passwort muss aus mind. 8 Zeichen bestehen.",
        english: "Please choose a new password and confirm it.<br>The password must at least be 8 characters long."
      },
      header: {
        headline: {
          german: "Passwort zurücksetzen",
          english: "Reset password"
        }
      }
    };
    this.mcp = React.createRef();
  }

  onField1Change(e) {
    this.setState({ pwd1: e.target.value, fail: false });
  }
  onField2Change(e) {
    this.setState({ pwd2: e.target.value, fail: false });
  }

  checkValidity() {
    if (!Boolean(this.state.pwd1.length)) return { valid: false, id: "EMPTY" };
    if (!Boolean(/.{8,}/.test(this.state.pwd1))) return { valid: false, id: "LENGTH" };
    if (!Boolean(this.state.pwd1 === this.state.pwd2)) return { valid: false, id: "MISMATCH" };
    return { valid: true };
  }

  sendData() {
    // Check if both passwords are the same
    let check = this.checkValidity();
    if (!check.valid) {
      switch (check.id) {
        case "EMPTY":
          return this.setState({ fail: MESSAGES.pwdIsEmpty });
        case "LENGTH":
          return this.setState({ fail: MESSAGES.pwdTooShort });
        case "MISMATCH":
          return this.setState({ fail: MESSAGES.pwdMismatch });
        default:
          return;
      }
    }
    this.setState({
      isSending: true
    });
    DataFetcher.fetch("pwd/set", { passwordResetToken: this.userID, pwd: this.state.pwd1 })
      .then(response => {
        this.setState({
          isSending: false
        });
        if (response.success) {
          return this.setState({ isFinished: true });
        } else {
          return this.setState({ fail: response.msg });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  onMCPClick() {
    if (!this.state.isFinished) {
      this.sendData();
    } else {
      return this.setState({ gotoLogin: true });
    }
  }

  render() {
    let inputPlaceholder = "...";
    if (User.isRegistered) return <Redirect to="/hub" />;
    if (this.state.gotoLogin) return <Redirect to="/login" />;
    return (
      <Fragment>
        <Header mode="welcome" config={this.state.header} />
        <div id="main" className="main register">
          <p className="intro">
            <LocElement {...this.state.intro} />
          </p>
          <div className="content">
            {this.state.isFinished && (
              <>
                <h2 className="title">{MESSAGES.finished.heading}</h2>
                {MESSAGES.finished.paragraphs.map((item, index) => {
                  return <p key={`fin-line-${index}`}>{item}</p>;
                })}
              </>
            )}
            {!this.state.isFinished && (
              <div className="value-holder">
                <label htmlFor="pwd1">Neues Passwort</label>
                <div className="ll-formgroup">
                  <div className={`ll-formelement ll-formelement-input ${this.state.focus1 ? "focus" : ""}`}>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <input
                      id={"pwd1"}
                      name={"pwd1"}
                      type={this.state.field1Hidden ? "password" : "text"}
                      autoComplete={"new-password"}
                      placeholder={this.state.focus1 ? "" : inputPlaceholder}
                      value={this.state.pwd1}
                      onFocus={() => {
                        this.setState({ focus1: true, fail: false });
                      }}
                      onBlur={() => {
                        this.setState({ focus1: false });
                      }}
                      onChange={this.onField1Change.bind(this)}
                    />
                    {/* <button
                      className={`iconHolder changeType ${this.state.field1Hidden ? 'on' : 'off'}`}
                      onClick={() => {
                        this.setState({ field1Hidden: !this.state.field1Hidden });
                      }}
                    >
                      <Icon icon="remove_red_eye" />
                    </button> */}
                  </div>
                  <label htmlFor="pwd2" className="has-margin-top">
                    Passwort bestätigen
                  </label>
                  <div className={`ll-formelement ll-formelement-input ${this.state.focus2 ? "focus" : ""}`}>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <input
                      id={"pwd2"}
                      name={"pwd2"}
                      type={this.state.field2Hidden ? "password" : "text"}
                      autoComplete={"off"}
                      placeholder={this.state.focus2 ? "" : inputPlaceholder}
                      value={this.state.pwd2}
                      onFocus={() => {
                        this.setState({ focus2: true, fail: false });
                      }}
                      onBlur={() => {
                        this.setState({ focus2: false });
                      }}
                      onChange={this.onField2Change.bind(this)}
                      // disabled={!Boolean(this.state.pwd1.length)}
                    />
                    {/* <button
                      className={`iconHolder changeType ${this.state.field2Hidden ? 'on' : 'off'}`}
                      onClick={() => {
                        this.setState({ field1Hidden: !this.state.field2Hidden });
                      }}
                    >
                      <Icon icon="remove_red_eye" />
                    </button> */}
                  </div>
                  {this.state.fail && <p className="fail">{this.state.fail}</p>}
                </div>
              </div>
            )}
          </div>
        </div>
        <MCP
          disabled={this.state.isSending}
          ref={this.mcp}
          onClick={e => {
            this.onMCPClick();
          }}
          mode={this.state.mcpMode}
          progress={0}
          handleKey={true}
        />
      </Fragment>
    );
  }
}
export default ResetPwd;
