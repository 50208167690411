import React from "react";
import { Link } from "react-router-dom";
import FormElementView from "./FormElementView";
import User from "./../User";
import LlFeInput from "./../components/formelements/Input";
import { Group as LlFeGroup, ValidationTypes as LlFeGroupValidationTypes } from "./../components/formelements/Group";
import uuid from "react-uuid";
import LocElement from "./../components/LocElement";
import DataFetcher from "./../utils/DataFetcher";
import DataPersister from "./../utils/DataPersister";
import Loader from "react-loaders";

class Login extends FormElementView {
  constructor(props) {
    super(props);
    this.state = {
      mcpMode: "register",
      mcpDisabled: true,
      currentScreen: 0,
      isAuthing: false,
      loginFailed: false,

      header: {
        headline: {
          german: "Login",
          english: "Login"
        }
      }
    };

    this._screens = [
      (() => {
        let o = {
          _id: uuid(),
          _pId: uuid(),
          key: "username",
          givenValue: null,
          // secHeadline: {german: "Gib deine persönlichen Zugangsdaten ein."},
          intro: { german: "Du bist noch nicht registriert? Dann klicke #[link;hier;/registration]" },
          label: { german: "Benutzername" },
          pKey: "password",
          pLabel: { german: "Passwort" }
        };
        o.ele = this._createFormFragment(
          o,
          <>
            <LlFeGroup ref={this._cv} onChange={this._currentValueChanged.bind(this)} className="ll-formgroup" validationType={LlFeGroupValidationTypes.ALL}>
              <LlFeInput name={o.key} key={o._id} id={o._id} placeholder="…" />
              <label htmlFor={o._pId} className="has-margin-top">
                <LocElement {...o.pLabel} />
              </label>
              <LlFeInput name={o.pKey} key={o._pId} id={o._pId} type="password" changeType={"text"} />
            </LlFeGroup>
            <Link className="resetPwd" to={"/reqpwreset"}>
              Passwort vergessen?
            </Link>
          </>
        );
        return o;
      })()
    ];
  }

  _finishedWithView() {
    let vals = super._finishedWithView();
    let alreadyFetchedPool = DataPersister.getItem("flow");
    if (alreadyFetchedPool) {
      alreadyFetchedPool = alreadyFetchedPool.pool.pool;
      vals = Object.assign({}, vals, { alreadyFetchedPool });
    }

    this.setState({ isAuthing: true, mcpDisabled: true });

    DataFetcher.fetch("login/check", vals).then(response => {
      if (response.success) {
        User.login(response.user);
        if (response.flow) DataPersister.setItem("flow", response.flow);
        return this.setState({ currentScreen: -1, isAuthing: false, loginFailed: false, mcpDisabled: true });
      }

      this.setState({ isAuthing: false, loginFailed: true, mcpDisabled: false });
    });
  }

  render() {
    let injection = null;
    if (this.state.isAuthing) {
      injection = (
        <div className="small-overlay not-clickable">
          <Loader type="line-scale-pulse-out-rapid" active />
          <label>Checki authentifiziert dich...</label>
        </div>
      );
    }
    if (this.state.loginFailed) {
      injection = (
        <p className="fail">
          Sind deine Login-Daten korrekt?
          <br />
          Bitte überprüfe deine Eingabe.
        </p>
      );
    }

    return super.render(injection);
  }
}

export default Login;
