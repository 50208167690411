//NOTE: in bridge-dev-mode %BRIDGE-URL%/pwa/content coder can look up available localizations
import dotty from 'dotty';
import striptags from 'striptags';

class Localizator {
  constructor(){
    this._data = {};
  }

  set data(data) {
    this._data = data;
  }

  getLocale(key, exchange = {}, removeTags = false) {
    let keys = Object.keys(exchange);
    let loc = dotty.get(this._data, key);
    if (keys.length === 0) return removeTags ? JSON.parse(striptags(JSON.stringify(loc))) : loc;
    loc = JSON.stringify(loc);
    loc = loc.split('_#LINEBREAK#_').join('\\n');
    /* {isSet[...]} */
    let reg = /{isSet\[(.*?)\]}(.*?){\/isSet}/g;
    loc = loc.replace(reg, (...match) => {
      let key = match[1].toLowerCase();
      if(exchange[key]){
        if(typeof exchange[key] === "string" && exchange[key].trim() === "") return '';
        return match[2]
      }
      return '';
    });
    /* {/isSet} */
    /* {andCondition[...]} */
    reg = /{andCondition\[(.*?)\]}(.*?){\/andCondition}/g;
    loc = loc.replace(reg, (...match) => {
      let keys = match[1].toLowerCase().split(",");
      let condition = true;
      keys.forEach(key => {
        if(!condition) return
        let hasToBe = true;
        if(key[0] === "!"){
          hasToBe = false;
          key = key.slice(1);
        }
        condition = exchange[key] === hasToBe;
      });
      if(!condition) return '';
      return match[2];
    });
    /* {/andCondition} */
    for (let i = 0; i < keys.length; i++) {
      loc = loc.split(`#${keys[i].toUpperCase()}#`).join(exchange[keys[i]] || '');
    }
    return JSON.parse(removeTags ? striptags(loc) : loc);
  }
}

export default new Localizator();
