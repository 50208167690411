import React, { Component, Fragment } from 'react';

import SingleChoice from './../components/templates/SingleChoice';
import MultipleChoice from './../components/templates/MultipleChoice';
import Inputs from './../components/templates/Inputs';
import RichtigFalsch from './../components/templates/RichtigFalsch';
import Lueckentext from './../components/templates/Lueckentext';
import Zuordnen from './../components/templates/Zuordnen';
import PreviewSingleChoice from './../components/templates/PreviewSingleChoice';
import Fragebogen from './../components/templates/Fragebogen';
import Feedback from './../components/templates/Feedback';
import ScreenInTheMiddle from './../components/ScreenInTheMiddle';
import MCP from './../components/MCP';

class Quizmaster extends Component
{
  constructor(props) {
    super(props);

    this.data = [];
    this.content = {};

    this.state = {}
    this.mcp = React.createRef();
    this._question = React.createRef();
    this._currTS = Date.now();
  }

  componentDidMount() {
    if (!this._userHasStartedTest) this.setMCPMode('interimScreen');
  }

  setMCPMode(idStrg) {
    this.mcp.current.setMode(idStrg);
  }

  disableMCP(bool) {
    this.mcp.current.setUnclickable(bool);
  }

  getMCPMode() {
    return this.mcp.current.getMode();
  }

  showTime(value) {
    this.setMCPMode('timer');
    this.mcp.current.showTime(value);
  }
  timesUp (data) {}

  startQuestion(config) {
    this._currTS = Date.now();
  }

  onTestFinish() {
    this.props.onFinish && this.props.onFinish();
  }

  proceed() {
    if(this._interimScreenFlag){
      this.setMCPMode('interimScreen');
      return this.setState({interimScreenFlag: this._interimScreenFlag});
    }
    this.setMCPMode('tracker');
    if (this.state.currentQuestion + 1 < this.state.maxQuestions) {
      return this.setState({ currentQuestion: this.state.currentQuestion + 1, interimScreenFlag: this._interimScreenFlag })
    }
    if (this.state.currentQuestion + 1 === this.state.maxQuestions) {
      return this.onTestFinish();
    }
  }

  getProgress() {
    return (this.state.currentQuestion) / this.state.maxQuestions;
  }

  startTimer(){}
  stopTimer(){}

  updateAnswers(data) {
    this._answers[this.data[this.state.currentQuestion]] = data;
    this._timeToSolve[this.data[this.state.currentQuestion]] = Date.now() - this._currTS;
  }

  onMCPClick() {
    //document.getElementsByClassName('App')[0].scroll(0,0);
    //document.body.scroll(0,0);
    //document.getElementById('root').scroll(0,0);
    //document.querySelector("html").scroll(0,0);
    document.getElementById('innerAppWrapper').scroll(0,0);
    if(this.getMCPMode() === "interimScreen") {
      this._interimScreenFlag = false;
      this._interimScreens.shift();
      return this.proceed();
    }

    let response = this._question.current.onMCPClick();

    switch(response.status) {
      case "start-question":
        return this.startQuestion(response);
      case "multipart-change":
        this.setMCPMode('timer');
        this.startTimer(response.timer);
        break;
      case "question-finished":
        this.stopTimer();
        this.updateAnswers(response.answers);
        return this.proceed();
      default:
        // return console.log('onMCPClick:', response);
        return null;
    }
  }

  _findContent(){
    for(let i = 0; i < this.content.length; i++)
      if(this.content[i]._id === this.data[this.state.currentQuestion])
        return this.content[i];
  }

  renderComponent() {
    let cont = this._findContent();
    let props = {
      ref: this._question,
      sendToMCP: this.setMCPMode.bind(this),
      disableMCP: this.disableMCP.bind(this),
      showTime: this.showTime.bind(this),
      startQuestion: this.startQuestion.bind(this),
      timesUp: this.timesUp.bind(this),
      data: cont,
      key: this.data[this.state.currentQuestion]
    };

    switch (cont.templateType) {
      case "singlechoice":
        return (<SingleChoice {...props} />);
      case "multiplechoice":
        return (<MultipleChoice {...props} />);
      case "input":
        return (<Inputs {...props} />);
      case "richtig_falsch":
        return (<RichtigFalsch {...props} />);
      case "lueckentext":
        return (<Lueckentext {...props} />);
      case "zuordnen":
        return (<Zuordnen {...props} />);
      case "previewsinglechoice":
        return (<PreviewSingleChoice {...props} />);
      case "fragebogen":
        return (<Fragebogen {...props} />);
      case "feedback":
        return (<Feedback {...props} />);
      default: return null;
    }
  }

  render() {
    return (
      <Fragment>
        {
          this.state.interimScreenFlag && this._interimScreens[0] && this._interimScreens[0].content &&
          <ScreenInTheMiddle {...this._interimScreens[0].content} />
        }
        { !this.state.interimScreenFlag && this.renderComponent() }
        <MCP ref={this.mcp} onClick={(e) => {this.onMCPClick(e)}} mode={this.state.mcpMode} progress={this.getProgress()} />
      </Fragment>
    );
  }
}

export default Quizmaster
