import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import 'what-input';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './utils/DataPersister';
import './utils/DataFetcher';
import './utils/Localizator';
import './scss/index.scss';
import './User';
import './utils/Events2Use';
import './BodySpy';

// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//   dsn: "https://f892e8bec4064f6fa0cafe886f93bf50@o1037103.ingest.sentry.io/6004804",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// Backbutton functionality
const history = require("history").createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>
  , document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* NOTE: REMOVE WHEN TESTED THAT innerHEIGHT alone works
const observer = new MutationObserver((mutationsList, observer) => {
  let keyboardOpeningTags = ['input', 'select', 'textarea'];
  console.log(window.innerHeight);
  window.requestAnimationFrame(() => {
    console.log("resize to (2)", window.innerHeight);
  })
  let ds = $html.dataset;

  if(ds && ds.whatelement && keyboardOpeningTags.includes(ds.whatelement))
    return $body.classList.add("virtual-keyboard-open");

    //setTimeout(() => {
      //div.remove();
      $body.classList.remove("virtual-keyboard-open");
    //}, 10)
  //$body.classList.remove("virtual-keyboard-open");
  return;
  let div = document.createElement('div');
  div.setAttribute('style', `height: 100%; width: 10px; position: absolute; top: 0; left: 0; background: #${Math.floor(Math.random()*16777215).toString(16)};`);
  $body.append(div);
  window.requestAnimationFrame(() => {
    div.remove();
  })
});
//observer.observe($html, {attributes: true});

/*
window.addEventListener('resize', () => {
  console.log('resize');
});
if('onorientationchange' in window)
  window.addEventListener('orientationchange', () => {
    console.log('orientationchange');
    check();
  });



console.log(window.screen);
const initialScreenSize = window.screen.height;
const check = () => {
  let kbactive = window.screen.height;
  if(initialScreenSize !== kbactive)
    console.log("KB OPENED");
  else
    console.log("KB CLOSED");
};

check();


whatInput.registerOnChange((type) => {
  console.log('intent', type);
}, 'intent');

whatInput.registerOnChange((type) => {
  console.log('input', type);
}, 'input');

whatInput.registerOnChange((type) => {
  console.log('element', type);
}, 'element');
*/
