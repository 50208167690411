import React, { Component } from "react";
import Assistant from "./assistant/Assistant";
import SpeechBubble from "./SpeechBubble";
import LocElement from "./LocElement";

import QRCode from "react-qr-code";

class ScreenInTheMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assistantFinished: false,
      assistantMode: props.assistantMode || "idle",
      addAssistantClasses: props.addAssistantClasses || null
    };
  }

  onAssistantStatus(response) {
    if (response.status === "idle") {
      return this.setState({ assistantFinished: true });
    }
  }

  render() {
    // let smallerThen1024px = window.innerWidth <= 1024;
    let bigLink,
      smallLink = null;
    if (this.props.linkTo && this.props.linkTo.button === "smallBubble") smallLink = this.props.linkTo;
    if (this.props.linkTo && this.props.linkTo.button === "bigBubble") bigLink = this.props.linkTo;
    let cssClasses = ["screen-in-the-middle"];
    // let cssClasses = ["screen-in-the-middle", "is-mb-column", "is-tb-column"];
    // cssClasses.push(this.props.bigSpeechBubble && this.props.smallSpeechBubble ? "is-dt-column" : "is-dt-row");
    let pdfBtnClasses = ["pdfButton"];
    let qrClasses = ["qrBox"]
    if (!this.state.assistantFinished) {
      cssClasses.push("bubblesHidden");
      pdfBtnClasses.push("btnHidden");
      qrClasses.push("qrHidden");
    }

    return (
      <div className={cssClasses.join(" ")}>
        {this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="main" mode="small" orientation="bottom" text={this.props.bigSpeechBubble} link={bigLink} />}
        {this.props.bigSpeechBubble && !this.props.smallSpeechBubble && <SpeechBubble type="main" mode="big" orientation="bottom" text={this.props.bigSpeechBubble} link={bigLink} />}
        <Assistant mode={this.state.assistantMode} animationClasses={this.state.addAssistantClasses} reportStatus={this.onAssistantStatus.bind(this)} />
        {this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="side" mode="small" orientation="top" text={this.props.smallSpeechBubble} link={smallLink} />}
        {this.props.linkTo && (
          <div className="group" style={{ display: "flex" }}>
            <div className="left">
              <a className={pdfBtnClasses.join(" ")} href={this.props.linkTo.href} target={this.props.linkTo.target}>
                <LocElement {...this.props.linkTo.btn_label} />
              </a>
            </div>
            <div className="right">
              <div className={qrClasses.join(" ")}>
                <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={this.props.linkTo.href} viewBox={`0 0 256 256`} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ScreenInTheMiddle;
