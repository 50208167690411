import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Hub from './views/Hub';
import SplashScreen from './views/SplashScreen';
import Login from './views/Login';
import Welcome from './views/Welcome';
import Registration from './views/Registration';
import PageNotFound from './views/PageNotFound';
import RequestPwReset from './views/RequestPwReset';
import ResetPwd from './views/ResetPwd';
import Footer from './components/Footer';
import PrivateRoute from './components/routing/PrivateRoute';

function App() {
  return (
    <div className="App">
      <div id="innerAppWrapper">
        <Switch>
          <Route path="/" component={Welcome} exact />
          <Route path="/registration" component={Registration} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/reqpwreset" component={RequestPwReset} exact />
          <Route path="/resetpwd/:id" component={ResetPwd} exact />
          <PrivateRoute path="/hub" component={Hub} exact />
          <Route component={PageNotFound} />
        </Switch>
      </div>
      <Footer/>
    </div>
  );
}

export default SplashScreen(App);
