if (["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)) document.body.classList.add("is-ios");

class BodySpy {
  constructor() {
    window.addEventListener("resize", this.onResize.bind(this));
    window.addEventListener("orientationchange", this.onResize.bind(this));
    this.onResize();
  }

  resizeStage() {
    let h = `${window.innerHeight}px`;
    let a = [document.body];
    let $root = document.getElementById("root");
    if ($root) a.push($root);
    let $app = document.querySelector(".App");
    if ($app) a.push($app);
    let $innerAppWrapper = document.getElementById("innerAppWrapper");
    if ($innerAppWrapper) a.push($innerAppWrapper);
    a.forEach($e => {
      $e.style.maxHeight = h;
      $e.style.minHeight = h;
    });
  }

  onResize() {
    this.resizeStage();
    setTimeout(this.resizeStage.bind(this), 440);
    setTimeout(this.resizeStage.bind(this), 1000);
  }
}

export default new BodySpy();
