import React, { Component, Fragment } from "react";
import Header from "./../components/Header";
import LocElement from "./../components/LocElement";
import MCP from "./../components/MCP";
import User from "./../User";
import { Redirect } from "react-router-dom";

import DataFetcher from "./../utils/DataFetcher";

const MESSAGES = {
  emptyUsername: "Bitte gib einen Benutzernamen ein.",
  finished: {
    heading: "Anfrage wurde versendet",
    paragraphs: ["Bitte schau in dein E-Mail-Postfach. Dort findest du eine Nachricht mit weiteren Anweisungen."]
  }
};

class RequestPwReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mcpMode: "register",
      isSending: false,
      isFinished: false,
      gotoLogin: false,
      focus: false,
      username: "",
      fail: false,
      intro: {
        german: "Setze dein Passwort zurück. Gib dafür deinen registrierten Benutzernamen ein:",
        english: "Reset your password. Enter your registered user name:"
      },
      header: {
        headline: {
          german: "Passwort vergessen?",
          english: "Password lost?"
        }
      }
    };
    this.mcp = React.createRef();
  }

  onFieldChange(e) {
    this.setState({ username: e.target.value, fail: false });
  }

  sendData() {
    let valid = Boolean(this.state.username.trim() !== "")
    if (valid) {
      this.setState({
        isSending: true
      });
      DataFetcher.fetch("pwd/reset", { username: this.state.username }).then(response => {
        this.setState({
          isSending: false
        });
        if (response.success) {
          return this.setState({ isFinished: true });
        } else {
          return this.setState({ fail: response.msg });
        }
      }).catch(error => {
        console.error(error);
      });
    } else {
      return this.setState({ fail: MESSAGES.emptyUsername });
    }
  }

  onMCPClick() {
    if (!this.state.isFinished) {
      this.sendData();
    } else {
      return this.setState({ gotoLogin: true });
    }
  }

  render() {
    let inputPlaceholder = "...";
    if (User.isRegistered) return <Redirect to="/hub" />;
    if (this.state.gotoLogin) return <Redirect to="/login" />;
    return (
      <Fragment>
        <Header mode="welcome" config={this.state.header} />
        <div id="main" className="main register">
          <p className="intro"><LocElement {...this.state.intro} /></p>
          <div className="content">
            {this.state.isFinished && (
              <>
                <h2 className="title">{MESSAGES.finished.heading}</h2>
                {MESSAGES.finished.paragraphs.map((item, index) => {
                  return <p key={`fin-line-${index}`}>{item}</p>;
                })}
              </>
            )}
            {!this.state.isFinished && (
              <div className="value-holder">
                <label htmlFor="email">Benutzername</label>
                <div className="ll-formgroup">
                  <div className={`ll-formelement ll-formelement-input ${this.state.focus ? "focus" : ""}`}>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      autoComplete={"off"}
                      placeholder={this.state.focus ? "" : inputPlaceholder}
                      value={this.state.username}
                      onFocus={() => {
                        this.setState({ focus: true });
                      }}
                      onBlur={() => {
                        this.setState({ focus: false });
                      }}
                      onChange={this.onFieldChange.bind(this)}
                    />
                  </div>
                  {this.state.fail && <p className="fail">{this.state.fail}</p>}
                </div>
              </div>
            )}
          </div>
        </div>
        <MCP
          disabled={this.state.isSending}
          ref={this.mcp}
          onClick={e => {
            this.onMCPClick();
          }}
          mode={this.state.mcpMode}
          progress={0}
          handleKey={true}
        />
      </Fragment>
    );
  }
}
export default RequestPwReset;
