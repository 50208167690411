import React, { Component } from 'react';
import LocElement from './LocElement';

class SecHeadline extends Component
{
  render() {
    let css = ["second-head"];
    if(this.props.example) css.push("example");
    return (
      <div className={css.join(" ")}>
        { this.props.example ? (<span className="xmpl"><LocElement {...this.props.example[0].exampleHeadline}/></span>) : null }
        <h2><LocElement {...this.props.text}/></h2>
      </div>
    );
  }
}

export default SecHeadline
