import React, { Component, Fragment } from 'react';

class PageNotFound extends Component
{
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        {/* <Header mode="welcome" config={this.state.header} /> */}
        <div id="main" className="template not-found">
          <div className="content">
            <p>Die Seite konnte nicht gefunden werden</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PageNotFound
