import React, { Component, Fragment } from "react";
import Header from "./../components/Header";
import ScreenInTheMiddle from "./../components/ScreenInTheMiddle";
import User from "./../User";
import Localizator from "./../utils/Localizator";
import MCP from "./../components/MCP";
import Test from "./Test";
import Questionnaire from "./Questionnaire";
import Survey from "./Survey";
import Tutorial from "./Tutorial";

import { AnimationTypes } from "./../components/assistant/Assistant";

class Hub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flags: User.getDataEntry("flags"),
      isWelcoming: true,
      tutorialShown: User.getDataEntry("consent.tutorial") || false,
      mcpMode: "proceed",
      oriental: false
    };
    this.mcp = React.createRef();
    this.tutorial = React.createRef();
    this.orientationHandler = this.onOrientationChange.bind(this);
  }

  setFlags() {
    let flags = User.getDataEntry("flags");
    this.setState({ flags });
  }

  getUserStatus() {
    if (User.getDataEntry("flags.surveyFinished") && User.getDataEntry("consent.maySeeResults")) {
      // User has finished the survey and may see the maySeeResults
      return { status: "complete", results: true };
    }
    if (User.getDataEntry("flags.surveyFinished") && !User.getDataEntry("consent.maySeeResults")) {
      // User has finished the survey and may not see the maySeeResults
      return { status: "complete", results: false };
    }
    if ((!User.getDataEntry("flags.testFinished") && Object.keys(User.getDataEntry("answers.test")).length !== 0) || (User.getDataEntry("flags.testFinished") && !User.getDataEntry("flags.questFinished")) || (User.getDataEntry("flags.testFinished") && !User.getDataEntry("flags.surveyFinished"))) {
      // User has started the test but not finished
      return { status: "unfinished" };
    }

    // Fresh User
    return { status: "new-user" };
  }

  getWelcomeText() {
    if (this.getUserStatus().status === "complete" && this.getUserStatus().results)
      return {
        headerHead: Localizator.getLocale("abschluss_mit_zertifikat.header"),
        bigSpeechBubble: Localizator.getLocale("abschluss_mit_zertifikat.speechbubble_1"),
        smallSpeechBubble: Localizator.getLocale("abschluss_mit_zertifikat.speechbubble_2"),
        assistantMode: AnimationTypes.GATE_SQUARE,
        addAssistantClasses: [AnimationTypes.EYES_GLOW]
      };

    if (this.getUserStatus().status === "complete" && !this.getUserStatus().results)
      return {
        headerHead: Localizator.getLocale("abschluss_ohne_zertifikat.header"),
        bigSpeechBubble: Localizator.getLocale("abschluss_ohne_zertifikat.speechbubble_1"),
        smallSpeechBubble: Localizator.getLocale("abschluss_ohne_zertifikat.speechbubble_2"),
        assistantMode: AnimationTypes.GATE_SQUARE,
        addAssistantClasses: [AnimationTypes.EYES_GLOW]
      };

    if (this.getUserStatus().status === "unfinished")
      return {
        //welcomeAgain
        headerHead: Localizator.getLocale("willkommen.headline"),
        bigSpeechBubble: Localizator.getLocale("begruessung_nach_anmeldung.speechbubble_1", { VORNAME: User.getDataEntry("personal.firstname"), Nachname: User.getDataEntry("personal.lastname") }),
        smallSpeechBubble: Localizator.getLocale("begruessung_nach_anmeldung.speechbubble_2"),
        assistantMode: AnimationTypes.WINKEN,
        addAssistantClasses: null
      };

    if (this.getUserStatus().status === "new-user")
      return {
        //welcomeFresh
        headerHead: Localizator.getLocale("willkommen.headline"),
        bigSpeechBubble: Localizator.getLocale("begruessung_nach_registrierung.speechbubble_1", { VORNAME: User.getDataEntry("personal.firstname"), Nachname: User.getDataEntry("personal.lastname") }),
        smallSpeechBubble: Localizator.getLocale("begruessung_nach_registrierung.speechbubble_2"),
        assistantMode: AnimationTypes.WINKEN,
        addAssistantClasses: null
      };
  }

  onTutorialFinish() {
    User.setDataEntry("consent.tutorial", true);
    this.setState({ tutorialShown: true });
  }
  onTutorialClick() {
    this.tutorial.current.remoteClick();
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.orientationHandler);
  }

  onOrientationChange(e) {
    // this._pageFooterRef.current.style.webkitTransform = 1;
    // if (!this._pseudoNode) this._pseudoNode = document.createElement('div');
    // this._pseudoNode.classList.add('maleben');
    // document.body.appendChild(this._pseudoNode);
    setTimeout(() => {
      this.setState({ oriental: true }, () => {
        setTimeout(() => {
          this.setState({ oriental: false });
        }, 50);
      });
    }, 50);
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.orientationHandler);
  }

  render() {
    let welcomeText = this.getWelcomeText();
    let content = null;
    let resultLink = this.getUserStatus().status === "complete" && this.getUserStatus().results ? { button: "smallBubble", href: `${process.env.REACT_APP_BRIDGE_URL}/pwa/pdf/${User.getDataEntry("linkedAccount")}`, target: "_blank", btn_label: Localizator.getLocale("abschluss_mit_zertifikat.pdf_button_label") } : null;
    let orientalPseudoStyle = { opacity: 0 };
    if (this.state.isWelcoming || Object.values(this.state.flags).reduce((memo, v) => (memo = memo && v)))
      content = (
        <Fragment>
          <Header mode="hub" config={{ headline: welcomeText.headerHead }} />
          <div id="main" className="template hub">
            <div className="content">
              <ScreenInTheMiddle {...welcomeText} linkTo={resultLink} />
            </div>
          </div>
          <MCP
            ref={this.mcp}
            onClick={e => {
              this.setState({ isWelcoming: false });
            }}
            mode={this.state.mcpMode}
            progress={0}
            disabled={this.getUserStatus().status === "complete"}
          />
        </Fragment>
      );
    else {
      if (!this.state.tutorialShown && !this.state.flags.testFinished)
        content = (
          <Fragment>
            <Tutorial ref={this.tutorial} onFinish={this.onTutorialFinish.bind(this)} />
            <MCP ref={this.mcp} onClick={this.onTutorialClick.bind(this)} mode={this.state.mcpMode} progress={0} />
          </Fragment>
        );
      if (this.state.tutorialShown && !this.state.flags.testFinished) content = <Test onFinish={this.setFlags.bind(this)} />;
      if (this.state.flags.testFinished && !this.state.flags.questFinished) content = <Questionnaire onFinish={this.setFlags.bind(this)} />;
      if (this.state.flags.testFinished && this.state.flags.questFinished && !this.state.flags.surveyFinished) content = <Survey onFinish={this.setFlags.bind(this)} />;
    }
    return (
      <Fragment>
        {content}
        {this.state.oriental && <div style={orientalPseudoStyle}>{Date.now()}</div>}
      </Fragment>
    );
  }
}
export default Hub;
